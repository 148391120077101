// ScrollNavbar.js
import React, { useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  CssBaseline,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";
import DrawerComponent from "./DrawerComponent";

const NavBar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const handleScroll = () => {};

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <AppBar
        position="fixed"
        elevation={4}
        style={{
          background: "black",
          width: "100%",
          transition: isMobile
            ? "none"
            : "width 0.5s ease-in-out, margin 0.5s ease-in-out, top 0.5s ease-in-out",
          marginLeft: 0,
          marginRight: 0,
          marginTop: 0,
        }}
      >
        <CssBaseline />
        <Toolbar
          sx={{
            paddingRight: "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {/* Left side: Logo and Name */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <Link
              to="/"
              style={{
                textDecoration: "none",
                display: "flex",
                alignItems: "center",

              }}
            >
              <img src={"assets/logo.png"} alt="Logo" style={{ height: "60px" }} />
              <Typography
                variant="h6"
                style={{
                  color: "#e0dccc",
                  fontFamily: "protest-revolution-font",
                  marginLeft: "10px",
                }}
              >
                ByteCrafts
              </Typography>
            </Link> 
          </div>

          {/* Center: Products and Services */}
          {isMobile ? (
            <DrawerComponent />
          ) : (
            <div
              style={{ display: "flex", justifyContent: "center", flexGrow: 1 }}
            >
              <Button
                color="inherit"
                style={{
                  borderTopLeftRadius: "40px",
                  borderBottomLeftRadius: "40px",
                  paddingLeft: "40px",
                  paddingRight: "40px",
                  variant: "contained",
                  backgroundColor: "#5C5B5B",
                  color: "#FBC3C3",
                }}
                component={Link}
                to="/"
              >
                Products
              </Button>
              <Button
                color="inherit"
                style={{
                  borderTopRightRadius: "40px",
                  borderBottomRightRadius: "40px",
                  paddingLeft: "40px",
                  paddingRight: "40px",
                  variant: "contained",
                  backgroundColor: "#5C5B5B",
                  color: "#FBC3C3",
                }}
                component={Link}
                to="/services"
              >
                Services
              </Button>
            </div>
          )}

          {!isMobile && (
            <Button
              component={Link}
              to="/contact"
              sx={{
                borderRadius: "40px",
                paddingLeft: "40px",
                paddingRight: "40px",
                marginLeft: "auto",
                background:
                  "linear-gradient(90deg, rgba(251, 195, 195, 0.95) 0%, rgba(205, 159, 159, 0.95) 45%, rgba(185, 143, 143, 0.95) 65%, rgba(149, 116, 116, 0.95) 100%)",
                color: "#F5F5F5",
                "&:hover": {
                  background:
                    "linear-gradient(90deg, rgba(251, 195, 195, 1) 0%, rgba(205, 159, 159, 1) 45%, rgba(185, 143, 143, 1) 65%, rgba(149, 116, 116, 1) 100%)", // Solid gradient on hover
                },
              }}
            >
              Let's Talk
            </Button>
          )}
        </Toolbar>
      </AppBar>

      {/* Additional AppBar on top */}
      {/* <AppBar
        position="fixed"
        elevation={0}
        style={{
          backgroundImage: "linear-gradient(90deg, #c2e9fb 24%, #cd5c5c 68%)",
          width: "100%",
          top: 0,
          zIndex: 2,
          opacity: 1,
          transition: "opacity 1.0s ease-in-out",
        }}
      >
        <Toolbar
          sx={{ justifyContent: "space-between" }}
          style={{ justifyContent: "center" }}
        >
          <Typography
            variant="h6"
            style={{
              color: "#14153E",
              textAlign: "center",
              fontFamily: "cursive",
            }}
          >
            "Beyond Binary: Shaping Tomorrow with BYTECRAFTS"
          </Typography>
        </Toolbar>
      </AppBar> */}
    </>
  );
};

export default NavBar;
