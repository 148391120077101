import React from "react";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import image1 from "./background.jpg";
import Image5 from "./clinicblue.png";
import Image6 from "./doctor.png";
import Image7 from "./patient.png";
import Image8 from "./xray.png";
import Image9 from "./delivery.png";
import image10 from "./care.png";
import image3 from "./refeaselogo.jpeg";

const Refease = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <div
        style={{
          position: "relative",
          width: "100vw",
          height: "100vh",
        }}
      >
        {/* Background image */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${image1})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            borderRadius: "10px",
            opacity: "0.5", // Adjust opacity as needed
            marginTop: "50px",
          }}
        />

        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
          }}
        >
          <Grid
            item
            style={{
              position: "absolute",
              width: "100%",
              height: isMobile ? "60%" : "40%",
              top: "25%",

              borderRadius: "10px",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              padding: "20px",
              textAlign: "center",
              marginTop: "50px",
            }}
          >
            <Typography variant="h2">REF-EASE</Typography>
            <Typography variant="h4" color="#207eb8">
              Revolutionize Dental Imaging With Seamless Collaboration...
            </Typography>
            <Typography variant="h6">
              A product revolutionize dental imaging with an advanced digital
              system, fostering seamless collaboration among clinics, doctors
              and patients. With features like hierarchical structures,
              collaborative workflows, enhancing efficiency an reduces
              paperwork....
            </Typography>
          </Grid>
        </Grid>

        {/* Close the main div */}
      </div>

      {/* Grid container outside the main div */}
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "40px" }}
      >
        <Grid
          container
          spacing={2}
          justify="center"
          style={{ padding: "20px", borderRadius: "10px" }}
        >
          {/* Grid items */}
          <Grid
            item
            xs={12}
            sm={3}
            container
            justify="center"
            alignItems="center"
          >
            <div style={{ textAlign: "center" }}>
              <img
                src={Image5}
                alt="Clinic Setup"
                style={{
                  width: "30%",
                  borderRadius: "8px",
                  marginBottom: "10px",
                }}
              />
              <Typography variant="h5">Clinic Setup:</Typography>
              <Typography variant="body1" style={{ color: "#757575" }}>
                Dental clinics onboard with REFEASE.
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            container
            justify="center"
            alignItems="center"
          >
            <div style={{ textAlign: "center" }}>
              <img
                src={Image6}
                alt="Doctor Addition"
                style={{
                  width: "30%",
                  borderRadius: "8px",
                  marginBottom: "10px",
                }}
              />
              <Typography variant="h5">Doctor Addition:</Typography>
              <Typography variant="body1" style={{ color: "#757575" }}>
                Clinics add doctors to establish a collaborative network.
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            container
            justify="center"
            alignItems="center"
          >
            <div style={{ textAlign: "center" }}>
              <img
                src={Image7}
                alt="Patient Details"
                style={{
                  width: "30%",
                  borderRadius: "8px",
                  marginBottom: "10px",
                }}
              />
              <Typography variant="h5">Patient Details:</Typography>
              <Typography variant="body1" style={{ color: "#757575" }}>
                Doctors input patient information for accurate records.
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            container
            justify="center"
            alignItems="center"
          >
            <div style={{ textAlign: "center" }}>
              <img
                src={Image8}
                alt="X-Ray Capture"
                style={{
                  width: "30%",
                  borderRadius: "8px",
                  marginBottom: "10px",
                }}
              />
              <Typography variant="h5">X-Ray Capture:</Typography>
              <Typography variant="body1" style={{ color: "#757575" }}>
                Effortlessly capture dental images within the platform.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>

      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <div style={{ margin: "0 20px" }}>
          <Grid
            container
            spacing={5}
            justify="center"
            style={{ padding: "20px", borderRadius: "10px" }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              container
              justify="center"
              alignItems="center"
            >
              <div style={{ textAlign: "center" }}>
                <img
                  src={Image9}
                  alt="Direct Delivery"
                  style={{
                    width: "30%",
                    borderRadius: "8px",
                    marginBottom: "10px",
                  }}
                />
                <Typography variant="h5">Direct Delivery:</Typography>
                <Typography variant="body1" style={{ color: "#757575" }}>
                  Images sent directly to the assigned doctor for prompt review.
                </Typography>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              container
              justify="center"
              alignItems="center"
            >
              <div style={{ textAlign: "center" }}>
                <img
                  src={image10}
                  alt="Prompt Patient Care"
                  style={{
                    width: "20%",
                    borderRadius: "8px",
                    marginBottom: "40px",
                    marginTop: "20px",
                  }}
                />
                <Typography variant="h5">Prompt Patient Care:</Typography>
                <Typography variant="body1" style={{ color: "#757575" }}>
                  Focused on delivering timely and efficient medical attention
                  to patients.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Grid>
        <div
          style={{
            minHeight: "50vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#e2e5f4",

            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            marginTop: "50px",
            position: "relative",
          }}
        >
          <Grid
            container
            spacing={1}
            style={{
              marginTop: "20px",
              padding: "60px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid item xs={12} sm={6} style={{ textAlign: "center" }}>
              <img
                src={image3}
                alt="dsadsaImage"
                style={{
                  width: "100%",
                  height: "auto",
                  maxWidth: "30%",
                  borderRadius: "20px",
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              style={{ padding: "20px", textAlign: "center" }}
            >
              {/* <Typography variant="h3" color='black' style={{ marginTop: '20px' }}>
      REF-EASE
    </Typography> */}
              {/* <Button variant="contained"  href="https://www.refease.in/" target="_blank" rel="noopener noreferrer"  style={{ backgroundColor: '#bab9cc', marginTop: '10px' }}>
      <Typography variant="body1"  color='black' style={{ textDecoration: 'none', color: 'black' }}>
        Explore Here
      </Typography>
      
    </Button> */}
              <Typography variant="h5" align="center">
                "Refease: Empowering Clinics and Doctors with Seamless
                Collaboration, Effortless X-Ray Workflow, and Unmatched Data
                Security."
              </Typography>
            </Grid>
          </Grid>
        </div>
        <Grid container justifyContent="center">
          <Grid
            item
            xs={12}
            sm={12}
            style={{ padding: "20px", textAlign: "center" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <div style={{ flex: 1, marginRight: "20px" }}>
                <Typography variant="h5" align="center">
                  Image Capture and Sharing
                </Typography>
                <Typography
                  align="center"
                  style={{
                    color: "#757575",
                    textAlign: "justify",
                    fontSize: "15px",
                  }}
                >
                  Effortless X-Ray Process: Seamless image capture within the
                  platform for a hassle-free experience. Direct Doctor Access:
                  Images are sent directly to the respective doctors for prompt
                  review and analysis.
                </Typography>
              </div>
              <div style={{ flex: 1, marginRight: "20px" }}>
                <Typography variant="h5" align="center">
                  Secure and Compliant
                </Typography>
                <Typography
                  align="center"
                  style={{
                    color: "#757575",
                    textAlign: "justify",
                    fontSize: "15px",
                  }}
                >
                  HIPAA Compliant: Ensures the highest standards of data
                  security and patient confidentiality. Access Controls:
                  Granular control over who can access patient data and images.
                </Typography>
              </div>
              <div style={{ flex: 1 }}>
                <Typography variant="h5" align="center" color="#01111e">
                  Collaborative Workflow
                </Typography>
                <Typography
                  align="center"
                  style={{
                    color: "#757575",
                    textAlign: "justify",
                    fontSize: "15px",
                  }}
                >
                  Clinic-Doctor Collaboration: Clinics add doctors, enabling
                  them to manage patient details efficiently. Doctor-Patient
                  Interaction: Doctors capture patient details, facilitating a
                  streamlined workflow for x-ray procedures.
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Refease;
