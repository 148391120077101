import { Grid, Typography } from "@mui/material";
import React from "react";
import { ImageGallery } from "react-image-grid-gallery";
import Section1 from "../HomePage/New/Section1";

function Services() {
  const imagesArray = [
    {
      alt: "BDCH",
      caption: "Bapuji Dental College and Hospital.",
      src: "/carousel-grid/screencapture-bapujidental-edu-2024-10-11-07_11_15.png",
    },
    {
      alt: "CDCOC",
      caption: "Classic Dental Clinic",
      src: "/carousel-grid/screencapture-classicdental-in-contact-2024-10-11-07_00_22.png",
    },
    {
      alt: "fasfa",
      caption: "Biophilinpharma",
      src: "/carousel-grid/screencapture-biophilinpharma-2024-10-11-07_08_36.png",
    },
    {
      alt: "fdafadfa",
      caption: "DashRDP",
      src: "/carousel-grid/screencapture-dashrdp-blog-2024-10-11-07_07_31.png",
    },
    {
      alt: "WebSite",
      caption: "Elites Power",
      src: "/carousel-grid/screencapture-elitespower-customers-2024-10-11-07_04_01.png",
    },
  ];
  const windowWidth = window.innerWidth;

  return (
    <Grid container spacing={0} style={{ width: "100%" }}>
      <Grid item xs={12}>
        <Section1 />
      </Grid>

      <Grid item xs={12} style={{ marginTop: "10px", margin: "20vh" }}>
        <ImageGallery
          imagesInfoArray={imagesArray}
          columnCount={"auto"}
          columnWidth={windowWidth / 3}
          gapSize={24}
          style={{ width: "100%" }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{ backgroundColor: "#F7990C", padding: "20px" }}
      >
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: {
                xs: "40px",
                sm: "60px",
                md: "70px",
                lg: "70px",
                xl: "70px",
              },
            }}
            fontWeight={700}
            color={"whitesmoke"}
          >
            Work With Us
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            fontWeight={700}
            color="whitesmoke"
            sx={{
              fontSize: {
                xs: "40px",
                sm: "60px",
                md: "70px",
                lg: "70px",
                xl: "70px",
              },
            }}
          >
            To build a better tomorrow!!!
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Services;
