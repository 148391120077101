import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import image from "./aboutus.png";
import AboutUsCard from "./AboutUsCard";

function AboutUs() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          style={{
            marginTop: "10vh",
            paddingLeft: isMobile ? "6vh" : "18vh",
          }}
        >
          <Grid item xs={12} style={{ paddingTop: "20px" }}>
            <Typography style={{ color: "#FB8686", fontSize: "24px" }}>
              Who we are
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: "10px" }}>
            <Typography
              sx={{
                color: "#000000",
                fontSize: { xs: "24px", sm: "50px", md: "50px", lg: "50px" },
                whiteSpace: "nowrap",
              }}
            >
              A Creative team shaping Digital
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: "10px" }}>
            <Typography
              sx={{
                color: "#000000",
                fontSize: { xs: "24px", sm: "50px", md: "50px", lg: "50px" },
                whiteSpace: "nowrap",
              }}
            >
              experiences with passion.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "4vh" }}>
          <img
            src={image}
            alt="About Us"
            style={{
              width: "100%",
              height: "auto",
              maxWidth: "100vw",
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            marginTop: "4vh",
            paddingLeft: isMobile ? "6vh" : "18vh",
          }}
        >
          <Grid item xs={12} style={{ paddingTop: "10px" }}>
            <Typography
              sx={{
                color: "#000000",
                fontSize: { xs: "23px", sm: "50px", md: "50px", lg: "50px" },
                whiteSpace: "nowrap",
              }}
            >
              Building Innovative Solutions that
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: "10px" }}>
            <Typography
              sx={{
                color: "#000000",
                fontSize: { xs: "24px", sm: "50px", md: "50px", lg: "50px" },
                whiteSpace: "nowrap",
              }}
            >
              elevate your <span style={{ color: "#FB8686" }}>Online</span>{" "}
              Presence.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <AboutUsCard />
    </div>
  );
}

export default AboutUs;
