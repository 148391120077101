import { Box, Grid, Link, Typography } from "@mui/material";
import React from "react";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import { useMediaQuery } from "@mui/material";

function FooterNew() {
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <Grid container style={{ padding: "16px", backgroundColor: "#000000" }}>
      <Grid
        item
        xs={12}
        style={{ display: "flex", flexDirection: isMobile ? "column" : "row" }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            display: "flex",
            flexDirection: isMobile ? "row" : "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <Grid item xs={12}>
            <Typography color="text.primary" gutterBottom>
              <img src="assets/logo.png" alt="Elite Logo" style={{ height: "80px" }} />
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ padding: "10px", marginLeft: "10px" }}>
            <Box
              style={{
                height: "30px",
                padding: "5px",
              }}
            >
              <Link
                href="https://www.facebook.com/profile.php?id=61555965698271"
                target="_blank"
                rel="noopener noreferrer"
                color="inherit"
                marginBottom="10px"
                marginRight="10px"
              >
                <Facebook style={{ color: "grey", fontSize: "40px" }} />{" "}
              </Link>
              <Link
                href="https://www.instagram.com/byte_crafts?igsh=MXQzcWV2enQ2eHBxcg=="
                target="_blank"
                rel="noopener noreferrer"
                color="inherit"
                marginBottom="10px"
                marginRight="10px"
              >
                <Instagram style={{ color: "grey", fontSize: "40px" }} />{" "}
              </Link>
              <Link
                href="https://www.twitter.com/"
                target="_blank"
                rel="noopener noreferrer"
                color="inherit"
              >
                <Twitter style={{ color: "grey", fontSize: "40px" }} />{" "}
              </Link>
            </Box>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            marginLeft: { xs: "3vh", sm: "undefined" },
          }}
        >
          <Typography
            sx={{ color: "#FB8686", fontWeight: 500, fontSize: "28px" }}
          >
            Get in touch
          </Typography>
          <Typography
            sx={{ color: "#FFFFFF", fontWeight: 500, fontSize: "28px" }}
          >
            Email
          </Typography>
          <Typography
            sx={{ color: "#FFFFFF", fontWeight: 500, fontSize: "28px" }}
          >
            Number
          </Typography>
          <Typography
            sx={{ color: "#FB8686", fontWeight: 500, fontSize: "28px" }}
          >
            Explore
          </Typography>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="left"
            sx={{
              flexDirection: { xs: "column", sm: "row" }, 
            }}
          >
            <Box display="flex" sx={{ flexWrap: { xs: "wrap", sm: "nowrap" } }}>
              <Typography
                onClick={() => {}}
                sx={{
                  color: "#FFFFFF",
                  fontWeight: 500,
                  fontSize: "28px",
                  marginRight: "8px",
                }}
              >
                Products
              </Typography>
              <Typography
                sx={{
                  color: "#FFFFFF",
                  fontWeight: 500,
                  fontSize: "28px",
                  marginRight: "8px",
                }}
              >
                |
              </Typography>
              <Typography
                onClick={() => {}}
                sx={{
                  color: "#FFFFFF",
                  fontWeight: 500,
                  fontSize: "28px",
                  marginRight: "8px",
                }}
              >
                Services
              </Typography>
              <Typography
                sx={{
                  color: "#FFFFFF",
                  fontWeight: 500,
                  fontSize: "28px",
                  marginRight: "8px",
                }}
              >
                |
              </Typography>
            </Box>
            <Typography
              onClick={() => {}}
              sx={{
                color: "#FFFFFF",
                fontWeight: 500,
                fontSize: "28px",
                marginTop: { xs: "8px", sm: 0 }, 
              }}
            >
              Contact Us
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default FooterNew;
