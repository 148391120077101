import React, { useState } from "react";

const NoticeBar = ({ brandNames }) => {
  const [animationPlayState, setAnimationPlayState] = useState("running");

  const handleMouseEnter = () => {
    setAnimationPlayState("paused");
  };

  const handleMouseLeave = () => {
    setAnimationPlayState("running");
  };
  const logoLink = "/";

  return (
    <div
      style={{
        width: "100%",
        overflow: "hidden",
        background:
          "linear-gradient(90deg, #F19696 0%, #DD8989 29%, #C77C7C 60%, #B06D6D 85.5%, #8B5656 100%)",
        height: "50px",
        position: "relative",
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center", // This centers items vertically
          justifyContent: "center", // This centers items horizontally
          whiteSpace: "nowrap",
          fontWeight: "bold",
          fontSize: "20px",
          animation: `moveRightToLeft 20s linear infinite ${animationPlayState}`,
          padding: "5px",
        }}
      >
        {brandNames.map((logo, index) => (
          <a
            key={index}
            href={logoLink}
            style={{ marginRight: "80px", color: "#FFFFFF" }}
          >
            <img
              src={logo}
              alt={`Brand Logo ${index + 1}`}
              style={{ height: "40px", width: "auto", verticalAlign: "middle" }}
            />
          </a>
        ))}
      </div>

      <style>
        {`
          @keyframes moveRightToLeft {
            0% {
              transform: translateX(100%);
            }
            100% {
              transform: translateX(-100%);
            }
          }
        `}
      </style>
    </div>
  );
};

export default NoticeBar;
