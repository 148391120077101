import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

function Cards({ brands, index }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      container
      spacing={2}
      style={{ marginTop: "4vh", position: "relative" }}
    >
      <Grid
        item
        xs={12}
        sm={6}
        sx={{
          order: { xs: 1, sm: index % 2 === 0 ? 1 : 2 },
          display: "flex",
          justifyContent: index % 2 === 0 ? "flex-start" : "flex-end",
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "80%",
            height: "60vh",
            position: "relative",
            border: "transparent",
            borderRadius: "4px",
            backgroundColor: "#fff4f4",
            padding: "10px",
            paddingTop: "10vh",
            overflow: "visible",
            marginLeft: index % 2 === 0 ? 0 : "auto",
            marginRight: index % 2 === 0 ? "auto" : 0,
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "80%",
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              left: index % 2 !== 0 ? "-40%" : "auto",
              right: index % 2 === 0 ? "-40%" : "auto",
              zIndex: 10,
              overflow: "hidden",
              borderRadius: "10px",
            }}
          >
            <img
              src={brands?.image}
              alt="Background"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "10px",
              }}
            />
          </Box>
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
        sx={{
          order: { xs: 2, sm: index % 2 === 0 ? 2 : 1 },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          paddingLeft: "10vh",
        }}
      >
        <div style={{ textAlign: isMobile ? "left" : "left" }}>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: isMobile ? "18px" : "50px",
              marginBottom: "8px",
            }}
          >
            {brands?.heading}
          </Typography>
          <Typography
            sx={{
              fontSize: isMobile ? "14px" : "20px",
              marginBottom: "4px",
            }}
          >
            {brands?.desc1}
            <br />
            {brands?.desc2}
            <br />
            {brands?.desc3}
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
}

export default Cards;
