import React from "react";
import {
  Grid,
  Typography,
  useMediaQuery,
  Button,
  Divider,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import image1 from "./stockslogo.png";
import image2 from "./arrowpic.png";
import image3 from "./clinic.png";
import image4 from "./trolley.png";
import image5 from "./manager.png";
import image6 from "./goods.png";

const Stocknest = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <div
        style={{
          minHeight: "90vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#362a3a",
        }}
      >
        <Grid
          container
          spacing={1}
          style={{ textAlign: "center", width: "100%" }}
        >
          {/* Grid for Text */}
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "30px",
            }}
          >
            <div style={{ padding: "20px", textAlign: "center" }}>
              <img
                src={image2}
                alt="Imgfage"
                style={{
                  width: "30%",
                  height: "auto",
                  borderRadius: "20px",
                  marginBottom: "20px",
                }}
              />
              <Typography
                variant="h2"
                style={{ color: "white", marginBottom: "10px" }}
              >
                STOCKNESTS
              </Typography>
              <Typography
                variant="h6"
                style={{ color: "#ec6c4b", marginBottom: "10px" }}
              >
                Product Management System
              </Typography>
              <Button
                variant="contained"
                href="https://www.stocknests.in/session/signin"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  backgroundColor: "#ec6c4b",
                  marginTop: "30px",
                  borderRadius: "15px",
                }}
              >
                <Typography
                  variant="body1"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Explore Here
                </Typography>
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
      <div style={{ padding: "20px", marginTop: "20px" }}>
        <Grid container spacing={isMobile ? 2 : 1} justify="center">
          <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
            <img
              src={image1}
              alt="Igdgmage"
              style={{
                width: "100%",
                height: "auto",
                maxWidth: "20%",
                borderRadius: "20px",
                marginLeft: isMobile ? "auto" : "40px",
              }}
            />
            <Typography
              variant="h5"
              style={{ fontSize: "25px", padding: "10px" }}
            >
              Empowering Clinics with Seamless Inventory Solutions
            </Typography>
            <Typography
              style={{ fontSize: "18px", textAlign: "justify", margin: "40px" }}
            >
              At Stock Nest, we specialize in providing comprehensive stock
              management solutions tailored specifically for clinics. Our
              company serves as the backbone for clinics' inventory control,
              ensuring seamless operations by regulating the stock of materials
              and equipment vital for their day-to-day functions.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={isMobile ? 2 : 4}
          style={{
            marginTop: "20px",
            padding: "20px",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          {/* First row */}
          <Grid
            item
            xs={12}
            sm={3}
            style={{ padding: "20px", textAlign: "center" }}
          >
            <Grid container spacing={2} direction="column" alignItems="center">
              <Grid item>
                <img
                  src={image3}
                  alt="Imgdgdage"
                  style={{
                    width: "100%",
                    maxWidth: "200px",
                    height: "auto",
                    borderRadius: "20px",
                    margin: "0 auto",
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  variant="h6"
                  style={{ fontSize: "20px", textAlign: "center" }}
                  alignItems="center"
                >
                  Clinic Role
                </Typography>
                <Typography
                  variant="h6"
                  style={{
                    fontSize: "15px",
                    textAlign: "justify",
                    color: "grey",
                  }}
                >
                  The physical location where stocks are held and utilized for
                  daily operations.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Divider orientation="vertical" flexItem />

          <Grid item xs={12} sm={3} style={{ padding: "20px" }}>
            <Grid container spacing={2} direction="column" alignItems="center">
              <Grid item>
                <img
                  src={image4}
                  alt="Igdgmage"
                  style={{
                    width: "100%",
                    maxWidth: "200px",
                    height: "auto",
                    borderRadius: "20px",
                    margin: "0 auto",
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  variant="h6"
                  style={{ fontSize: "20px", textAlign: "center" }}
                  alignItems="center"
                >
                  Stocks
                </Typography>
                <Typography
                  variant="h6"
                  style={{
                    fontSize: "15px",
                    textAlign: "justify",
                    color: "grey",
                  }}
                >
                  The materials and equipment essential for clinic functioning,
                  ranging from medical supplies to administrative necessities.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <div
        style={{
          padding: "20px",
          backgroundColor: "#fdf9fa",
          marginTop: "20px",
        }}
      >
        <Grid
          container
          spacing={isMobile ? 2 : 4}
          style={{
            marginTop: "20px",
            padding: "20px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* First row */}
          <Grid
            item
            xs={12}
            sm={3}
            style={{ padding: "20px", marginTop: "20px" }}
          >
            <Grid container spacing={2} direction="column" alignItems="center">
              <Grid item>
                <img
                  src={image5}
                  alt="Imgdgdage"
                  style={{
                    width: "100%",
                    maxWidth: "220px",
                    height: "auto",
                    borderRadius: "20px",
                    margin: "0 auto",
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  variant="h6"
                  style={{ fontSize: "20px", textAlign: "center" }}
                  alignItems="center"
                >
                  Manager Role
                </Typography>
                <Typography
                  variant="h6"
                  style={{
                    fontSize: "15px",
                    textAlign: "justify",
                    color: "grey",
                  }}
                >
                  Responsible for overseeing stock management operations,
                  including adding new items to inventory and issuing stocks as
                  needed.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Divider orientation="vertical" flexItem />

          <Grid
            item
            xs={12}
            sm={3}
            style={{ padding: "20px", marginTop: "20px" }}
          >
            <Grid container spacing={2} direction="column" alignItems="center">
              <Grid item>
                <img
                  src={image6}
                  alt="Imagdgge"
                  style={{
                    width: "100%",
                    maxWidth: "200px",
                    height: "auto",
                    borderRadius: "20px",
                    margin: "0 auto",
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  variant="h6"
                  style={{ fontSize: "20px", textAlign: "center" }}
                  alignItems="center"
                >
                  Stocks Flow
                </Typography>
                <Typography
                  variant="h6"
                  style={{
                    fontSize: "15px",
                    textAlign: "justify",
                    color: "grey",
                  }}
                >
                  Managers oversee both adding and issuing stocks, ensuring the
                  clinic maintains adequate supplies by incorporating new items
                  into the inventory system.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Stocknest;
