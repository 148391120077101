import React from "react";
import Section1 from "./New/Section1";
import NoticeBar from "./noticeBar";
import Cards from "./New/Cards";
import CardsMobile from "./New/CardsMobile";
import { useMediaQuery } from "@mui/material";

const brandNames = [
  "/logos/English LogoSAN.png",
  "/logos/baplogo.png",
  "/logos/imageo.png",
  "/logos/white_logo11.png",
];
const brands = [
  {
    heading: "Websitee Building",
    desc1: "We enhance your Digital presence",
    desc2: "through great websites, UX and",
    desc3: "design",
    image: "/homepage/web1.webp",
  },
  {
    heading: "Refease",
    desc1: "We revolutionize dental imaging",
    desc2: "with seamless collaborative digital",
    desc3: "solution",
    image: "/homepage/webref.webp",
  },
  {
    heading: "StockNests",
    desc1: "We Streamline your clinic's inventory",
    desc2: "management with tailored stock",
    desc3: "management system",
    image: "/homepage/webstock.webp",
  },
  {
    heading: "NamNews",
    desc1: "we help local newspapers to thrive",
    desc2: "with robust online presence",
    desc3: "",
    image: "/homepage/webnam.webp",
  },
];

function HomePageNew() {
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <div>
      <Section1 />
      <NoticeBar brandNames={brandNames} />

      {brands.map((brand, index) =>
        isMobile ? (
          <CardsMobile key={index} brands={brand} index={index + 1} />
        ) : (
          <Cards key={index} brands={brand} index={index + 1} />
        )
      )}
    </div>
  );
}

export default HomePageNew;
