import React from 'react';
import { Grid, Typography, useMediaQuery, Button ,Divider} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import image1 from './newsbg.jpg'; // Import your background image file
import image3 from './finerprint.jpg'
import image4 from './easyuse.jpg'
import image5 from './handshake.jpg'
import image6 from './income.jpg'
import image2 from './news paper5.jpg'
import image7 from './demographics.jpg'
import image8 from './audience1.jpg'
const Namnews = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <div
        style={{
          minHeight: '90vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#362a3a',
          backgroundImage: `url(${image1})`, // Add background image here
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          marginTop: '70px',
          position: 'relative', // Add relative positioning
        }}
      >
        <Grid container spacing={2}>
          {/* Left grid */}
          <Grid item xs={12} sm={6}>
            {/* Add content for left grid if needed */}
          </Grid>
          {/* Right grid */}
          <Grid item xs={12} sm={6}>
            <div style={{ padding: '20px' }}>
              <Typography variant="h4" gutterBottom style={{ color: 'white' }}>
                Namnews
              </Typography>
              <Typography variant="body1" style={{ color: 'white' }}>
                Namnews is the only solution for newspapers looking to embrace the digital age.
              </Typography>
              <Button
                variant="contained"
                href="https://www.stocknests.in/session/signin"
                target="_blank"
                rel="noopener noreferrer"
                style={{ backgroundColor: '#ec6c4b', marginTop: '30px', borderRadius: '15px' }}
              >
                <Typography variant="body1" style={{ textDecoration: 'none', color: 'white' }}>
                  Explore Here
                </Typography>
              </Button>
            </div>
          </Grid>
        </Grid>
        {/* Arrow down button */}
        
      </div>
      <div  style={{ textAlign: 'center',marginTop:"50px" }}>
  <Typography variant='h4'>
"A New Horizon for Traditional News: Embrace the Digital Wave with Namnews!"</Typography>
</div>

      <div>
        <Grid container style={{padding:'40px',marginTop:'50px',display: 'flex', alignItems: 'center', justifyContent: 'center',backgroundColor:'#414143'}}>
          <Grid item xs={12} sm={6} style={{ textAlign: 'center' }}>
            <img src={image2} alt="Imdasdage" style={{ width: '100%', height: 'auto', maxWidth: '30%', borderRadius: '20px' }} />
          </Grid>
          <Grid item xs={12} sm={6} style={{ padding: '20px', textAlign: 'center' }}>
            <Typography variant="h5" color='white'   style={{ marginTop: '20px',textAlign: 'justify'}}>
            Namnews tackles a pressing challenge for Indian local newspapers, helping
them adapt to the changing digital landscape. As print editions wane,
maintaining a robust online presence becomes crucial to prevent a decline in
readership.
            </Typography>
            
           
          </Grid>
          
        </Grid>
      </div>
<Grid container spacing={2} style={{marginTop:'30px',padding:'40px'}}>

  <Grid item xs={6} sm={3}>
    <div style={{ textAlign: 'center' }}>
      <img src={image3} alt="Imagdae 1" style={{ width: '80%', height: 'auto', borderRadius: '10px' }} />
      <Typography variant="h6" style={{fontSize: '20px', textAlign: 'center', }} alignItems="center">
                Digital Fingerprint   
                </Typography>
      <Typography variant="subtitle1">Expand your newspaper's online presence effortlessly.</Typography>
    </div>
  </Grid>

  <Grid item xs={6} sm={3}>
    <div style={{ textAlign: 'center' }}>
      <img src={image4} alt="Imadasdage 2" style={{ width: '80%', height: 'auto', borderRadius: '10px' }} />
      <Typography variant="h6" style={{fontSize: '20px', textAlign: 'center', }} alignItems="center">
      Easy-to-Use Platform
                   </Typography>
      <Typography variant="subtitle1">Navigate our intuitive platform with ease.</Typography>
    </div>
  </Grid>

  <Grid item xs={6} sm={3}>
    <div style={{ textAlign: 'center' }}>
      <img src={image5} alt="Imadasdsge 3" style={{ width: '80%', height: 'auto', borderRadius: '10px' }} />
      <Typography variant="h6" style={{fontSize: '20px', textAlign: 'center', }} alignItems="center">
      Brand Recognition  
                   </Typography>
      <Typography variant="subtitle1">Establish and strengthen your newspaper's brand identity in the digital domain.</Typography>
    </div>
  </Grid>

  <Grid item xs={6} sm={3}>
    <div style={{ textAlign: 'center' }}>
      <img src={image6} alt="Imagdsade 4" style={{ width: '80%', height: 'auto', borderRadius: '10px' }} />
      <Typography variant="h6" style={{fontSize: '20px', textAlign: 'center', }} alignItems="center">
      Passive Income
                   </Typography>
      <Typography variant="subtitle1">Create additional revenue streams through our digital channels.</Typography>
    </div>
  </Grid>
</Grid>
<div  style={{ textAlign: 'center',marginTop:"50px" }}>
  <Typography variant='h4'>
" Target Audience and Market Potential"</Typography>
</div>

<Grid container spacing={isMobile ? 2 : 4} style={{ marginTop: '20px', padding: '20px', justifyContent: 'center',  textAlign: 'center' }}>
          {/* First row */}

          <Grid item xs={12} sm={3} style={{ padding: '20px', textAlign: 'center' }}>
            <Grid container spacing={2} direction="column"  alignItems="center">
              <Grid item>
                <img src={image7} alt="Imagdasdae" style={{ width: '100%', maxWidth: '230px', height: 'auto', borderRadius: '20px', margin: '0 auto' }} />
              </Grid>
              <Grid item>
              <Typography variant="h6" style={{fontSize: '20px', textAlign: 'center', }} alignItems="center">
                   Demographics
                </Typography>
                <Typography variant="h6" style={{fontSize: '15px', textAlign: 'justify',color:'grey' }}>
                The physical location where stocks are held and utilized for daily operations.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Divider orientation="vertical" flexItem />

          <Grid item xs={12} sm={3} style={{ padding: '20px' }}>
            <Grid container spacing={2} direction="column" alignItems="center">
              <Grid item>
                
                <img src={image8} alt="Imagdasde" style={{ width: '100%', maxWidth: '200px', height: 'auto', borderRadius: '20px', margin: '0 auto' }} />
              </Grid>
              <Grid item>
              <Typography variant="h6" style={{fontSize: '20px', textAlign: 'center', }} alignItems="center">
                   Bring your audience 
                </Typography>
                <Typography variant="h6" style={{ fontSize: '15px', textAlign: 'justify',color:'grey' }}>
                   The materials and equipment essential for clinic functioning, ranging from medical supplies to administrative necessities.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          </Grid> 
          
       
         


    </>
  );
};

export default Namnews;
