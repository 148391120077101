import { Box, Card, Grid, Typography } from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";

function AboutUsCard() {
  const data = [
    {
      msg: "Commitment to Excellence",
      desc: "Our Dynamic team thrives on continuous improvement. Each member is dedicated to perfecting their craft, ensuring every project benefits from a blend of experience and innovation.",
    },
    {
      msg: "Innovative Spirit",
      desc: "At ByteCrafts, we blend creativity with expertise to craft digital solutions that stand out.",
    },
    {
      msg: "Client Centric Approach",
      desc: "We tailor our services to meet your unique needs, delivering top notch solutions that align with your business goals.",
    },
  ];

  function Carddd({ msg }) {
    return (
      <Card
        sx={{
          background:
            "linear-gradient(90deg, rgba(217, 217, 217, 0.29) 0%, rgba(115, 115, 115, 0.29) 100%)",
          boxShadow: "0px 4px 4px 0px #00000040",
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          padding: "2vh",
          borderRadius: "37px",
          paddingLeft: "2vh",
          marginBottom: "4vh",
          height: "180px", // Fixed height for card
          overflow: "hidden", // Prevent overflow
          transition: "transform 1.0s ease-in-out",
          "&:hover": {
            transform: "scale(1.05)",
          },
        }}
      >
        <Box
          sx={{
            width: "60%",
            position: "relative",
            padding: "1vh",
            "&:hover .description": {
              display: "block",
            },
          }}
        >
          <Typography
            className="title"
            sx={{
              fontSize: { xs: "24px", sm: "28px", md: "28px", lg: "28px" },
              color: "#000000",
              lineHeight: "1.2",
            }}
          >
            {msg.msg}
          </Typography>
          <Typography
            className="description"
            sx={{
              display: "none",
              color: "#737373",
              mt: 1,
              fontSize: { xs: "14px", sm: "16px", md: "16px", lg: "16px" },
              lineHeight: "1.5",
              paddingRight: "1vh",
              textOverflow: "ellipsis",
            }}
          >
            {msg.desc}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "40%",
            position: "relative",
            height: "100%",
          }}
        >
          <AddIcon
            sx={{
              position: "absolute",
              right: "10px",
              fontSize: "50px",
              color: "#FFFFFF",
            }}
          />
        </Box>
      </Card>
    );
  }

  return (
    <Grid
      container
      spacing={2}
      sx={{
        marginTop: "4vh",
        background:
          "linear-gradient(250.83deg, rgba(234, 125, 125, 0.03) 1.89%, rgba(251, 134, 134, 0.03) 2.28%, rgba(223, 119, 119, 0.03) 44.93%, rgba(188, 100, 100, 0.03) 97.93%, rgba(169, 90, 90, 0.03) 97.93%, rgba(149, 80, 80, 0.03) 97.93%)",
        borderRadius: "91px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "2vh",
      }}
    >
      <Grid
        item
        xs={12}
        sm={6}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: "40px", sm: "50px", md: "50px", lg: "50px" },
            color: "#000000",
            padding: "10vh",
            textAlign: "center",
          }}
        >
          Our Beliefs, Culture & Values
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} sx={{ padding: "4vh" }}>
        {data.map((msg, index) => (
          <Carddd key={index} msg={msg} />
        ))}
      </Grid>
    </Grid>
  );
}

export default AboutUsCard;
